<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1 class="systemTitle">Contacts</h1>
          <Contact paginationStatus="true"/>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import Contact from "@/components/business/Contact";
export default {
  name: "Contacts",
  components: {
    Contact,
  },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.statisticBox {
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin: 0 -10px;
  li {
    width: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    box-shadow: 0 4px 5px #efefef;
    margin: 0 10px;
    border-radius: 8px;
    span {
      font-size: 20px;
    }
    strong {
      font-size: 40px;
    }
  }
}
.orderFilters {
  display: flex;
  justify-content: space-between;
  list-style: none;
  width: 100%;
  padding-left: 0;
  margin-bottom: 15px;
  li {
    width: 100%;
    padding: 10px 20px;
    background: #efefef;
    &.active {
      background: #0878d4;
      a {
        color: #fff;
      }
    }
    a {
      font-size: 14px;
      display: flex;
      justify-content: center;
      color: #000;
    }
  }
}
</style>