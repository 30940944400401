<template>
  <section>
    <v-container class="container-custom">
      <v-form ref="contactRef">
        <v-row>
          <v-col class="pt-10">
            <h1 class="heading">Contact Us</h1>
          </v-col>
        </v-row>
        <v-row class="justify-content">
          <v-col md="7" class="pb-0">
            <v-text-field
              v-model="fullName"
              placeholder="Fullname"
              outlined
              :rules="fullNameRules"
            ></v-text-field>
          </v-col>
          <v-col md="7" class="pb-0">
            <v-text-field
              v-model="email"
              placeholder="Email"
              outlined
              :rules="emailRules"
            ></v-text-field>
          </v-col>
          <v-col md="7" class="pb-0">
            <v-text-field
              v-model="phoneNumber"
              placeholder="Phone"
              outlined
              :rules="phoneRules"
            ></v-text-field>
          </v-col>
          <v-col md="7" class="pb-0">
            <v-textarea
              v-model="description"
              placeholder="Description"
              outlined
              :rules="descRules"
            ></v-textarea>
          </v-col>
          <v-col md="7" class="pb-15">
            <v-btn
              class="primary"
              large
              v-if="!loadingSubmit"
              @click="contactUs"
              >Send</v-btn
            >
            <v-btn class="primary" large v-if="loadingSubmit" loading></v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-snackbar v-model="status">
        {{ text }}
      </v-snackbar>
    </v-container>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Contact",
  data: () => ({
    loadingSubmit: false,
    fullName: undefined,
    email: undefined,
    phoneNumber: undefined,
    description: undefined,
    text: undefined,
    status: undefined,
    fullNameRules: [(v) => !!v || "Fullname is required."],
    emailRules: [(v) => !!v || "Email is required."],
    phoneRules: [(v) => !!v || "Phone Number is required."],
    descRules: [(v) => !!v || "Description is required."],
  }),
  methods: {
    ...mapActions(["postContact"]),
    async contactUs() {
      if (this.$refs.contactRef.validate()) {
        this.loadingSubmit = true;
        let data = {
          fullName: this.fullName,
          email: this.email,
          phoneNumber: this.phoneNumber,
          description: this.description,
        };
        await this.postContact(data);
        this.text = this.allContact.data.message;
        this.status = this.allContact.status;
        this.loadingSubmit = false;
      }
    },
  },
  computed: {
    ...mapGetters(["allContact"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.justify-content {
  justify-content: center !important;
}
.heading {
  text-align: center;
  font-size: 50px;
}
</style>