<template>
  <section class="tableBox">
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr class="greenBack">
            <th class="text-left">Fulname</th>
            <th class="text-left">Email</th>
            <th class="text-left">Phone</th>
            <th class="text-left">Fullname</th>
            <th class="text-left">Description</th>
          </tr>
        </thead>
        <tbody v-if="!loading">
          <tr
            v-for="(data, index) in contacts"
            :key="index + 'book'"
            class="rowBox"
          >
            <td>{{ data.fullName }}</td>
            <td>{{ data.email }}</td>
            <td>{{ data.phoneNumber }}</td>
            <td>{{ data.description }}</td>
            <td class="text-capitalize">
              {{ data.createdAt | moment("MMMM Do YYYY") }}
            </td>
          </tr>
          <tr v-if="pagination.total == 0" class="rowBox">
            <td colspan="7">No Contant Inquiry Found</td>
          </tr>
        </tbody>
        <tbody v-if="loading">
          <tr class="paddingTB">
            <td colspan="7">
              Loading
              <v-progress-circular
                indeterminate
                size="20"
                width="2"
                class="ml-2"
              ></v-progress-circular>
            </td>
          </tr>
        </tbody>
        <tfoot v-if="!loading">
          <tr>
            <td colspan="7">
              <ul class="pagination">
                <li v-if="pagination.previous">
                  <v-icon @click="prev(pagination.previous)">
                    mdi-chevron-left
                  </v-icon>
                </li>
                <li>
                  <span v-if="pagination.previous"
                    >{{
                      pagination.previous + 1 > 1
                        ? pagination.previous + 1 * limit
                        : 1
                    }}
                    -</span
                  >
                  <span v-if="pagination.next"
                    >{{
                      pagination.next - 1 > 1 ? pagination.next - 1 * limit : 1
                    }}
                    -</span
                  >
                  {{ pagination.total }} Items
                </li>
                <li v-if="pagination.next">
                  <v-icon @click="next(pagination.next)">
                    mdi-chevron-right
                  </v-icon>
                </li>
              </ul>
            </td>
          </tr>
        </tfoot>
      </template>
    </v-simple-table>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Contact",
  data: () => ({
    limit: 10,
    page: 1,
    contacts: [],
    loading: true,
    pagination: undefined,
  }),
  components: {
    // Loading,
  },
  methods: {
    ...mapActions(["getContacts"]),
    async next(data) {
      this.loading = true;
      await this.getContacts({
        limit: this.limit,
        page: data,
      });
      this.contacts = this.allContacts.results;
      this.pagination = this.allContacts.pagination;
      this.loading = false;
    },
    async prev(data) {
      this.loading = true;
      await this.getContacts({
        limit: this.limit,
        page: data,
      });
      this.contacts = this.allContacts.results;
      this.pagination = this.allContacts.pagination;
      this.loading = false;
    },
  },
  computed: {
    ...mapGetters(["allContacts"]),
  },
  async created() {
    await this.getContacts({
      limit: this.limit,
      page: this.page,
    });
    this.contacts = this.allContacts.results;
    this.pagination = this.allContacts.pagination;
    this.loading = false;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.statisticBox {
  display: flex;
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin: 0 -10px;
  li {
    width: 100%;
    padding: 20px;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-flow: column;
    box-shadow: 0 4px 5px #efefef;
    margin: 0 10px;
    border-radius: 8px;
    span {
      font-size: 20px;
    }
    strong {
      font-size: 40px;
    }
  }
}
.orderImage {
  width: 100px;
  object-fit: contain;
  height: 100px;
}
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.tableBox {
  border: 1px solid #d7d3d3;
}
</style>